<template>
  <on-click-outside :do="handleClickOutside">
    <div class="relative" :class="{ 'border': border, 'border-black': open, 'border-grey-21': !open }">
      <div @click="toogleOpen">
        <slot name="button">
          <button type="button" class="w-full block bg-blue text-white font-semibold rounded px-4 py-2 focus:outline-0">
            Click to open
          </button>
        </slot>
      </div>
      <div v-if="open" class="absolute z-50 w-full" :class="[`bg-${dropdownBg}`, additionalClassesForDropdown ]">
        <slot name="content">
          Dropdown
        </slot>
      </div>
    </div>
  </on-click-outside>
</template>

<script>
import OnClickOutside from 'theme/components/theme/OnClickOutside'

export default {
  name: 'CustomDropdown',
  props: {
    keyDropdown: {
      type: String,
      required: false,
      default: ''
    },
    additionalClassesForDropdown: {
      type: String,
      required: false,
      default: ''
    },
    dropdownBg: {
      type: String,
      required: false,
      default: 'black'
    },
    border: {
      type: Boolean,
      required: false,
      default: true
    },
    checkIsOpen: {
      type: Function,
      required: false
    }
  },
  components: {
    OnClickOutside
  },
  beforeMount () {
    this.$bus.$on('close-dropdown-component', this.closeDropdownComponent)
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    shareStateWithParent (state) {
      if (typeof this.checkIsOpen !== 'undefined') {
        this.checkIsOpen(state);
      }
    },
    handleClickOutside () {
      if (this.open) {
        this.open = false
        this.shareStateWithParent(this.open)
      }
    },
    closeDropdownComponent (key = false) {
      if (this.keyDropdown !== '' && key === this.keyDropdown) {
        this.open = false
        this.shareStateWithParent(this.open)
      }
    },
    toogleOpen() {
      this.open = !this.open
      this.shareStateWithParent(this.open)
    }
  }
}
</script>
