import config from 'config'
import { mapGetters } from 'vuex'

export const CategorySort = {
  name: 'SortBy',
  data () {
    return {
      sortby: '',
      sortbyLabel: 'Select Option'
    }
  },
  beforeMount () {
    this.$bus.$on('list-change-sort', this.onSortOrderChanged)
  },
  beforeDestroy () {
    this.$bus.$off('list-change-sort', this.onSortOrderChanged)
  },
  mounted () {
    const sort = this.getCurrentCategoryProductQuery && this.getCurrentCategoryProductQuery.sort ? this.getCurrentCategoryProductQuery.sort : null
    this.toggleSort(sort)
  },
  methods: {
    // emit to category, todo: move all logic inside
    sort () {
      this.$bus.$emit('list-change-sort', { attribute: this.sortby })
      this.openSort = !this.openSort
    },
    toggleSort(sort) {
      if (sort) {
        Object.keys(this.sortingOptions).forEach((option, index) => {
          let elem = this.sortingOptions[option]

          if (elem === sort) {
            this.sortby = sort
            this.sortbyLabel = option
          }
        })
      }
    },
    onSortOrderChanged (param) {
      this.toggleSort(param.attribute)
    }
  },
  computed: {
    ...mapGetters('category', ['getCurrentCategoryProductQuery']),
    sortingOptions () {
      return config.products.sortByAttributesLbp
    }
  }
}
