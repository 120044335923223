var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('article',{staticClass:"category"},[_c('div',{staticClass:"container mx-auto lg:px-17 hidden lg:block"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full"},[_c('breadcrumbs',{attrs:{"routes":_vm.getBreadcrumbs,"active-route":_vm.category.name}})],1)])]),_vm._v(" "),_c('div',{staticClass:"container mx-auto lg:px-6 pb-10 lg:pb-0"},[_c('div',{staticClass:"category__nav pt-xl lg:py-xl lg:flex lg:justify-between"},[_c('custom-dropdown',{staticClass:"filter-by-brands",attrs:{"key-dropdown":'filter-by-brands',"check-is-open":_vm.checkIsOpenFilterByBrand,"dropdownBg":"black","additionalClassesForDropdown":"pt-7.5 pb-7 pl-5","border":false}},[_c('template',{slot:"button"},[_c('a',{staticClass:"filter-by-brands__link block text-center",class:{ 'filter-by-brands__link--active bg-black' : _vm.open, 'bg-white' : !_vm.open },attrs:{"href":"javascript:void(0);"}},[(_vm.currentProductBrand.length)?_c('i',{staticClass:"icon-brand filter-by-brands__icon",class:[
                  'filter-by-brands__icon--' + _vm.currentProductBrand[0].icon,
                  'icon-'+ _vm.currentProductBrand[0].iconLogo,
                  { 'text-white' : _vm.open, 'text-black' : !_vm.open }
                ]}):_c('p',{staticClass:"uppercase text-h5 font-bold tracking-lg",class:{ 'text-white' : _vm.open, 'text-black' : !_vm.open }},[_vm._v("\n                "+_vm._s(_vm.category.name)+"\n              ")]),_vm._v(" "),_c('i',{staticClass:"ss-gizmo ss-navigatedown absolute top-0 right-20px text-h5",class:{ 'text-white' : _vm.open, 'text-black' : !_vm.open }})])]),_vm._v(" "),(_vm.open)?_c('template',{slot:"content"},[_c('ul',{staticClass:"list-none p-0"},_vm._l((_vm.productBrands),function(item,key){return _c('li',{key:key,staticClass:"cursor-pointer filter-by-brands__item",class:{ 'mb-sm' : key !== _vm.productBrands.length - 1 }},[_c('router-link',{staticClass:"hover:text-primary2 filter-by-brands__item text-grey-15 tracking-lg font-bold leading-loose block uppercase pt-md pb-md pl-16 relative text-h5 slow-color-change",attrs:{"to":_vm.localizedRoute(item.link)},nativeOn:{"click":function($event){return _vm.brandSelected($event, key)}}},[_c('i',{staticClass:"filter-by-brands__item-icon absolute left-0 top-0",class:['filter-by-brands__item-icon--' + item.icon, 'icon-'+ item.icon]}),_vm._v("\n                  "+_vm._s(item.label)+"\n                ")])],1)}),0)]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"filter-by-type px-xl py-7"},[_c('ul',{staticClass:"lg:flex shadow-md lg:border"},[_c('li',{staticClass:"mb-6.5 lg:mb-0"},[_c('button',{staticClass:"inline-block slow-color-change whitespace-no-wrap outline-none rounded-0 border-0 leading-none px-20 py-5 text-center text-h6 uppercase tracking-sm w-full",class:{
                  'bg-black hover:bg-primary2 text-white' : _vm.category.level <= 3,
                  'bg-white text-black hover:bg-primary hover:text-white' : _vm.category.level > 3
                },attrs:{"type":"button"},on:{"click":_vm.resetFilters}},[_vm._v("\n                "+_vm._s(_vm.$t('All'))+"\n              ")])]),_vm._v(" "),_c('li',{staticClass:"lg:border-l border-black border lg:border-r-0 lg:border-t-0 lg:border-b-0"},[_c('custom-dropdown',{attrs:{"key-dropdown":'filter-by-type',"check-is-open":_vm.checkIsOpenSubcategoriesFilter,"dropdownBg":"white","border":false}},[_c('template',{slot:"button"},[_c('a',{staticClass:"filter-by-type__link block text-center pl-md pr-13 bg-white text-dark tracking-lg uppercase text-h6 leading-lg h-13",attrs:{"href":"javascript:void(0);"}},[_c('span',{staticClass:"inline-block align-middle leading-none"},[_vm._v("\n                      "+_vm._s(_vm.$t('Product Types'))+"\n                    ")]),_vm._v(" "),_c('i',{staticClass:"ss-gizmo ss-navigatedown absolute top-0 right-10px text-h5 text-black"})])]),_vm._v(" "),(_vm.openSubcategoriesFilter)?_c('template',{slot:"content"},[_c('ul',{staticClass:"list-none p-0 -mx-px border-l border-r border-b"},_vm._l((_vm.productsTypes),function(item,key){return _c('li',{key:key,staticClass:"cursor-pointer filter-by-type_item border-t border-grey-21"},[_c('router-link',{staticClass:"hover:bg-dark hover:text-white filter-by-type__item tracking-lg uppercase text-h6 leading-lg px-md block slow-color-change",class:{
                          'bg-dark text-white' : _vm.activeSubcat(item.url_key),
                          'text-dark' : !_vm.activeSubcat(item.url_key)
                        },attrs:{"to":_vm.formatCategoryLink(item)},nativeOn:{"click":function($event){_vm.openSubcategoriesFilter = !_vm.openSubcategoriesFilter}}},[_vm._v("\n                        "+_vm._s(item.name)+"\n                      ")])],1)}),0)]):_vm._e()],2)],1)]),_vm._v(" "),(_vm.redeemButtonDisplay.length)?_c('router-link',{staticClass:"mt-3 block slow-color-change whitespace-no-wrap outline-none focus:outline-none rounded-0 hover:bg-primary2 border-0 text-white text-center text-h5 uppercase tracking-sm bg-black py-1 px-5 m-auto",attrs:{"to":_vm.localizedRoute(_vm.redeemButtonDisplay[0].link + '/not-for-resale')}},[_vm._v("\n            Redeem Your Points\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"filter-by-sort mt-6 lg:ml-lg px-xl lg:px-0"},[_c('sort-by')],1)],1),_vm._v(" "),_vm._l((_vm.productsListing),function(item,key){return _c('product-listing',{key:key,staticClass:"lg:py-xl px-sm",attrs:{"columns":"5","products":item.products,"category-id":item.categoryId},scopedSlots:_vm._u([{key:"callout",fn:function(){return [_c('div',{staticClass:"text-black lg:text-xl font-black font-serif lg:leading-h2 product-listing__subheader text-lg leading-none lg:my-0 my-9.5 text-center"},[_vm._v("\n            "+_vm._s(_vm.getCurrentCategoryName(item))+"\n          ")])]},proxy:true}],null,true)})}),_vm._v(" "),(_vm.isCategoryEmpty)?_c('div',{staticClass:"py-8"},[_c('h4',{attrs:{"data-testid":"noProductsInfo"}},[_vm._v("\n          "+_vm._s(_vm.$t('No products found!'))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('Please change Your search criteria and try again. If still not finding anything relevant, please visit the Home page and try out some of our bestsellers!')))])]):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }