import { CategorySort } from 'theme/modules/catalog/components/CategorySort'
import config from 'config'

export default {
  name: 'SortBy',
  methods: {
    changeOrder (value, label) {
      this.sortby = value
      this.sortbyLabel = label
      // renamed to sort
      this.sort()
    }
  },
  computed: {
    sortByAttribute () {
      // renamed to sortingOptions
      return config.products.sortByAttributesLbp
    }
  },
  mixins: [CategorySort]
}
