<template>
  <div class="main">
    <article class="category">
      <div class="container mx-auto lg:px-17 hidden lg:block">
        <div class="flex">
          <div class="w-full">
            <breadcrumbs :routes="getBreadcrumbs" :active-route="category.name" />
          </div>
        </div>
      </div>
      <div class="container mx-auto lg:px-6 pb-10 lg:pb-0">
        <div class="category__nav pt-xl lg:py-xl lg:flex lg:justify-between">
          <custom-dropdown
            class="filter-by-brands"
            :key-dropdown="'filter-by-brands'"
            :check-is-open="checkIsOpenFilterByBrand"
            dropdownBg="black"
            additionalClassesForDropdown="pt-7.5 pb-7 pl-5"
            :border="false"
          >
            <template slot="button">
              <a href="javascript:void(0);" class="filter-by-brands__link block text-center" :class="{ 'filter-by-brands__link--active bg-black' : open, 'bg-white' : !open }">
                <i
                  v-if="currentProductBrand.length"
                  :class="[
                    'filter-by-brands__icon--' + currentProductBrand[0].icon,
                    'icon-'+ currentProductBrand[0].iconLogo,
                    { 'text-white' : open, 'text-black' : !open }
                  ]"
                  class="icon-brand filter-by-brands__icon"
                />
                <p v-else :class="{ 'text-white' : open, 'text-black' : !open }" class="uppercase text-h5 font-bold tracking-lg">
                  {{ category.name }}
                </p>
                <i class="ss-gizmo ss-navigatedown absolute top-0 right-20px text-h5" :class="{ 'text-white' : open, 'text-black' : !open }" />
              </a>
            </template>
            <template slot="content" v-if="open">
              <ul class="list-none p-0">
                <li
                  v-for="(item, key) in productBrands"
                  :key="key"
                  class="cursor-pointer filter-by-brands__item"
                  :class="{ 'mb-sm' : key !== productBrands.length - 1 }"
                >
                  <router-link :to="localizedRoute(item.link)" @click.native="brandSelected($event, key)" class="hover:text-primary2 filter-by-brands__item text-grey-15 tracking-lg font-bold leading-loose block uppercase pt-md pb-md pl-16 relative text-h5 slow-color-change">
                    <i class="filter-by-brands__item-icon absolute left-0 top-0" :class="['filter-by-brands__item-icon--' + item.icon, 'icon-'+ item.icon]" />
                    {{ item.label }}
                  </router-link>
                </li>
              </ul>
            </template>
          </custom-dropdown>
          <div class="filter-by-type px-xl py-7">
            <ul class="lg:flex shadow-md lg:border">
              <li class="mb-6.5 lg:mb-0">
                <button
                  class="inline-block slow-color-change whitespace-no-wrap outline-none rounded-0 border-0 leading-none px-20 py-5 text-center text-h6 uppercase tracking-sm w-full"
                  :class="{
                    'bg-black hover:bg-primary2 text-white' : category.level <= 3,
                    'bg-white text-black hover:bg-primary hover:text-white' : category.level > 3
                  }"
                  type="button"
                  @click="resetFilters"
                >
                  {{ $t('All') }}
                </button>
              </li>
              <li class="lg:border-l border-black border lg:border-r-0 lg:border-t-0 lg:border-b-0">
                <custom-dropdown
                  :key-dropdown="'filter-by-type'"
                  :check-is-open="checkIsOpenSubcategoriesFilter"
                  dropdownBg="white"
                  :border="false"
                >
                  <template slot="button">
                    <a href="javascript:void(0);" class="filter-by-type__link block text-center pl-md pr-13 bg-white text-dark tracking-lg uppercase text-h6 leading-lg h-13">
                      <span class="inline-block align-middle leading-none">
                        {{ $t('Product Types') }}
                      </span>
                      <i class="ss-gizmo ss-navigatedown absolute top-0 right-10px text-h5 text-black"></i>
                    </a>
                  </template>
                  <template slot="content" v-if="openSubcategoriesFilter">
                    <ul class="list-none p-0 -mx-px border-l border-r border-b">
                      <li v-for="(item, key) in productsTypes" :key="key" class="cursor-pointer filter-by-type_item border-t border-grey-21">
                        <router-link
                          :to="formatCategoryLink(item)"
                          @click.native="openSubcategoriesFilter = !openSubcategoriesFilter"
                          :class="{
                            'bg-dark text-white' : activeSubcat(item.url_key),
                            'text-dark' : !activeSubcat(item.url_key)
                          }"
                          class="hover:bg-dark hover:text-white filter-by-type__item tracking-lg uppercase text-h6 leading-lg px-md block slow-color-change"
                        >
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </template>
                </custom-dropdown>
              </li>
            </ul>
            <router-link
              v-if="redeemButtonDisplay.length"
              :to="localizedRoute(redeemButtonDisplay[0].link + '/not-for-resale')"
              class="mt-3 block slow-color-change whitespace-no-wrap outline-none focus:outline-none rounded-0 hover:bg-primary2 border-0 text-white text-center text-h5 uppercase tracking-sm bg-black py-1 px-5 m-auto">
              Redeem Your Points
            </router-link>
          </div>
          <div class="filter-by-sort mt-6 lg:ml-lg px-xl lg:px-0">
            <sort-by />
          </div>
        </div>
        <product-listing
          columns="5"
          v-for="(item, key) in productsListing"
          :products="item.products"
          :key="key"
          :category-id="item.categoryId"
          class="lg:py-xl px-sm"
        >
          <template #callout>
            <div class="text-black lg:text-xl font-black font-serif lg:leading-h2 product-listing__subheader text-lg leading-none lg:my-0 my-9.5 text-center">
              {{ getCurrentCategoryName(item) }}
            </div>
          </template>
        </product-listing>
        <div v-if="isCategoryEmpty" class="py-8">
          <h4 data-testid="noProductsInfo">
            {{ $t('No products found!') }}
          </h4>
          <p>{{ $t('Please change Your search criteria and try again. If still not finding anything relevant, please visit the Home page and try out some of our bestsellers!') }}</p>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Category from 'theme/mixins/pages/Category'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import CustomDropdown from 'theme/components/core/blocks/Category/CustomDropdown'
import ProductListing from 'theme/components/core/ProductListing'
import SortBy from 'theme/components/core/SortBy'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'Category',
  components: {
    Breadcrumbs,
    CustomDropdown,
    ProductListing,
    SortBy
  },
  mixins: [
    Category
  ],
  data () {
    return {
      open: false,
      openSubcategoriesFilter: false,
      selectedFilterByBrand: 0,
      categoryBreadcrumbs: [],
      listOfProducts: {},
      productBrands: [
        {
          id: 41,
          label: 'R+Co',
          icon: 'rco',
          iconLogo: 'rco',
          link: '/brands/r-and-co'
        },
        {
          id: 40,
          label: 'V76 by Vaughn',
          icon: 'v76',
          iconLogo: 'vaughn',
          link: '/brands/v76'
        },
        // {
        //   id: 42,
        //   label: 'Smith & Cult',
        //   icon: 'sc',
        //   iconLogo: 'smith-cult',
        //   link: '/brands/smith-and-cult'
        // },
        {
          id: 139,
          label: 'R+CO Bleu',
          icon: 'rco-bleu',
          iconLogo: 'rco-bleu',
          link: '/brands/bleu'
        },
        {
          id: 139,
          label: 'R+Color',
          icon: 'rco-color',
          iconLogo: 'rco-color',
          link: '/brands/r-and-color'
        }
      ]
    }
  },
  asyncData ({ store, route }) { // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    return new Promise((resolve, reject) => {
      store.dispatch('category/mergeSearchOptions', { // this is just an example how can you modify the search criteria in child components
        sort: 'position:asc'
        // searchProductQuery: builder().query('range', 'price', { 'gt': 0 }).andFilter('range', 'visibility', { 'gte': 2, 'lte': 4 }) // this is an example on how to modify the ES query, please take a look at the @vue-storefront/core/helpers for refernce on how to build valid query
      })
      resolve()
    })
  },
  computed: {
    ...mapGetters({
      currentCategory: 'category/getCurrentCategory',
      getAllCategories: 'category/getAllCategories',
      getCurrentCategoryProductQuery: 'category/getCurrentCategoryProductQuery'
    }),
    getBreadcrumbs () {
      let breadcrumbs = this.breadcrumbs.routes || []
      let categoryBreadcrumbs = [...breadcrumbs]

      if (categoryBreadcrumbs.length) categoryBreadcrumbs.splice(0, 2, {name: 'Home', route_link: '/'})

      return categoryBreadcrumbs
    },
    currentRoute () {
      return this.$route.name
    },
    currentPath () {
      return this.$route.path
    },
    categoriesList () {
      return this.$store.state.category.list
    },
    parentCategoryUrl () {
      let parentCategory = this.categoriesList.filter(item => this.category.brand_id === item.brand_id && item.level === 3)
      return parentCategory ? parentCategory[0]['url_key'] : this.currentRoute
    },
    productsListing () {
      let productsByCategory = {}
      let categoryChildren = this.category['children_data']

      if (categoryChildren) {
        let categories = this.getAllCategories
        let categoriesIds = []

        categoryChildren.forEach(category => {
          categoriesIds.push(category.id)
        })

        categories.forEach(
          item => {
            if (!categoriesIds.includes(item.id)) return

            if (this.getCurrentCategoryProductQuery.sort === 'position:asc') {
              const products = this.products.filter(product => {
                return product.hasOwnProperty('category_ids') && (product.category_ids || []).includes(item.id)
              })

              products.sort((a, b) => {
                if (!a.category || !b.category) return 0

                let first = a.category.find(cat => cat.category_id === item.id)
                let second =  b.category.find(cat => cat.category_id === item.id)
                return first.position - second.position
              })

              productsByCategory[`cat-${item.id}`] = {
                categoryId: item.id,
                currentCategory: item,
                products: products
              }
            } else {
              this.products.forEach(
                product => {
                  if (product.hasOwnProperty('category_ids')) {
                    if (!(product.category_ids || []).includes(item.id)) return
                    if (typeof productsByCategory[`cat-${item.id}`] === 'object') {
                      productsByCategory[`cat-${item.id}`].products.push(product)
                    } else {
                      productsByCategory[`cat-${item.id}`] = {
                        categoryId: item.id,
                        currentCategory: item,
                        products: [product]
                      }
                    }
                  }
                }
              )
            }
          }
        )
      } else {
        let categoryProducts = this.products.filter(product => (product.category_ids || []).includes(this.category.id))

        productsByCategory[`cat-${this.category.id}`] = {
          categoryId: this.category.id,
          currentCategory: this.category,
          products: categoryProducts
        }
      }

      return productsByCategory
    },
    productsTypes () {
      let productsTypes = []
      let id = (this.category.level === 3) ? this.category.id : this.category.parent_id

      productsTypes = this.getAllCategories.filter(item => item.parent_id === id && item.include_in_menu)
      return productsTypes
    },
    redeemButtonDisplay () {
      let currentBrand = this.productBrands.filter(brand => brand.id === this.category.id || brand.id === this.category.parent_id)
      return currentBrand || ''
    },
    currentProductBrand () {
      let currentBrand = this.productBrands.filter(brand => brand.id === this.category.id)
      return currentBrand || ''
    }
  },
  methods: {
    resetFilters () {
      let category = this.categoriesList.filter(item => this.currentCategory.parent_id === item.id && item.level === 3)
      let parentCategory = category.length ? category[0] : this.currentCategory
      this.$bus.$emit('list-change-sort', {
        attribute: this.$store.state.config.products.defaultSortBy.attribute + (this.$store.state.config.products.defaultSortBy.order
          ? ':' + this.$store.state.config.products.defaultSortBy.order
          : ''
        )
      })
      return this.$router.push(this.localizedRoute(formatCategoryLink(parentCategory)))
    },
    checkIsOpenFilterByBrand (state) {
      this.open = state
    },
    activeSubcat (item) {
      return this.currentPath.includes(item)
    },
    checkIsOpenSubcategoriesFilter (state) {
      this.openSubcategoriesFilter = state
    },
    brandSelected (event, key) {
      this.open = false
      this.selectedFilterByBrand = key
    },
    getCurrentCategoryName (item) {
      return item.currentCategory ? item.currentCategory.name : ''
    },
    formatCategoryLink(category) {
      return formatCategoryLink(category)
    }
  }
}

</script>
<style scoped lang="scss">
.filter-by-type {
  background-image: url('/assets/pattern-tile.png');
  background-repeat: repeat;
  background-position: left top;
}

.filter-by-brands {
  @screen lg {
    width: 240px;
  }

  &__link {
    transition: none;
    height: 100px;
    line-height: 100px;
  }

  &__item {
    &-icon {
      &--rco {
        @apply text-2h6;
        top: 10px;
        left: 7px;
      }

      &--v76,
      &--sc,
      &--rco-bleu {
        @apply text-lg;
      }

      &--v76 {
        top: 2px;
        left: 7px;
      }

      &--sc {
        top: 7px;
        left: -10px;
      }

      &--rco-bleu {
        top: 7px;
        left: -5px;
      }

      &--rco-color {
        top: 1rem;
      }
    }
  }

  &__icon {
    line-height: 100px;

    &--rco {
      @apply text-big;
    }

    &--v76 {
      @apply text-heading-page;
    }

    &--sc {
      @apply text-above-mid;
    }

    &--rco-bleu {
      @apply text-above-mid;
    }
  }
}

</style>
