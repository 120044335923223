import Category from '@vue-storefront/core/pages/Category'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import { baseFilterProductsQuery, isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import store from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'

export default {
  extends: Category,
  data () {
    return {
      pagination: {
        perPage: 1000,
        current: 0,
        enabled: false
      },
      lazyLoadProductsOnscroll: false
    }
  },
  preAsyncData ({ store, route }) {
    Logger.log('preAsyncData query setup')()
    const currentProductQuery = store.getters['category/getCurrentCategoryProductQuery']
    const sort = currentProductQuery && currentProductQuery.sort ? currentProductQuery.sort : config.entities.productList.sort

    store.dispatch('category/setSearchOptions', {
      populateAggregations: true,
      store: store,
      route: route,
      current: 0,
      perPage: 1000,
      sort,
      filters: config.products.defaultFilters,
      includeFields: config.entities.optimize && isServer ? config.entities.productList.includeFields : null,
      excludeFields: config.entities.optimize && isServer ? config.entities.productList.excludeFields : null,
      append: false
    })
  },
  async asyncData ({ store, route, context }) {
    Logger.info('Entering asyncData in Category Page (core)')()
    try {
      if (context) context.output.cacheTags.add(`category`)
      const defaultFilters = config.products.defaultFilters

      store.dispatch('category/resetFilters')
      EventBus.$emit('filter-reset')
      await store.dispatch('attribute/list', {
        filterValues: defaultFilters,
        includeFields: config.entities.optimize && isServer ? config.entities.attribute.includeFields : null
      })

      const categoryPayload = config.seo.useUrlDispatcher && !config.products.useShortCatalogUrls
        ? { key: 'url_path', value: route.path.substring(1) }
        : { key: config.products.useMagentoUrlKeys ? 'url_key' : 'slug', value: route.params.slug }

      const parentCategory = await store.dispatch('category/single', categoryPayload)
      let query = store.getters['category/getCurrentCategoryProductQuery']

      if (query && !query.searchProductQuery) {
        store.dispatch('category/mergeSearchOptions', {
          searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters)
        })
      }

      const subloaders = await store.dispatch('category/products', query)

      if (subloaders) {
        await Promise.all(subloaders)
        await EventBus.$emitFilter('category-after-load', { store: store, route: route })
      } else {
        throw new Error('Category query returned empty result')
      }
    } catch (err) {
      Logger.error(err)()
      throw err
    }
  },
  async beforeRouteEnter (to, from, next) {
    if (!isServer) {
      const currentUser = store.state.user.current
      const userShippingLocations = currentUser ? currentUser.extension_attributes.locations_data : []

      if (!userShippingLocations) return next()
      const brands = await store.getters['brands/getBrands']
      const brand = brands.find(brand => brand.slug === to.params.slug)

      if (brand) {
        let currentLocation = userShippingLocations.find(location => location.brand_id === brand.id && location.status === true)

        if (currentLocation) return next()
        next({ path: `/${to.params.slug}-stockist` })
      } else {
        next()
      }

      if (!from.name) {
        next(vm => {
          const defaultFilters = config.products.defaultFilters
          let parentCategory = store.getters['category/getCurrentCategory']
          let query = store.getters['category/getCurrentCategoryProductQuery']

          if (query && !query.searchProductQuery) {
            store.dispatch('category/mergeSearchOptions', {
              searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters),
              cacheOnly: true
            })
          }
          store.dispatch('category/products', query)
        })
      }
    } else {
      next()
    }
  },
  methods: {
    validateRoute (route = this.$route) {
      this.$store.dispatch('category/resetFilters')
      this.$bus.$emit('filter-reset')

      const categoryPayload = config.seo.useUrlDispatcher && !config.products.useShortCatalogUrls
        ? { key: 'url_path', value: route.path.substring(1) }
        : { key: config.products.useMagentoUrlKeys ? 'url_key' : 'slug', value: route.params.slug }

      this.$store.dispatch('category/single', categoryPayload).then(category => {
        if (!category) {
          this.$router.push(this.localizedRoute('/'))
        } else {
          this.pagination.current = 0
          let searchProductQuery = baseFilterProductsQuery(this.getCurrentCategory, config.products.defaultFilters)

          this.$bus.$emit('current-category-changed', this.getCurrentCategoryPath)
          this.mergeSearchOptions({ // base prototype from the asyncData is being used here
            current: this.pagination.current,
            perPage: this.pagination.perPage,
            store: this.$store,
            route: this.$route,
            append: false,
            populateAggregations: true
          })
          if (this.getCurrentCategoryProductQuery && !this.getCurrentCategoryProductQuery.searchProductQuery) {
            this.mergeSearchOptions({
              searchProductQuery
            })
          }
          this.$store.dispatch('category/products', this.getCurrentCategoryProductQuery)
          this.$bus.$emitFilter('category-after-load', { store: this.$store, route: route })
        }
      }).catch(err => {
        if (err.message.indexOf('query returned empty result') > 0) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t('The product, category or CMS page is not available in Offline mode. Redirecting to Home.'),
            action1: { label: i18n.t('OK') }
          })
          this.$router.push(localizedRoute('/', currentStoreView().storeCode))
        }
      })
    },
    onUserPricesRefreshed () {
      const defaultFilters = config.products.defaultFilters
      const categoryPayload = config.seo.useUrlDispatcher && !config.products.useShortCatalogUrls
        ? { key: 'url_path', value: this.$route.path.substring(1) }
        : { key: config.products.useMagentoUrlKeys ? 'url_key' : 'slug', value: this.$route.params.slug }

      this.$store.dispatch('category/single', categoryPayload).then((parentCategory) => {
        if (this.getCurrentCategoryProductQuery && !this.getCurrentCategoryProductQuery.searchProductQuery) {
          this.mergeSearchOptions({
            searchProductQuery: baseFilterProductsQuery(parentCategory, defaultFilters),
            skipCache: true
          })
        }
        this.$store.dispatch('category/products', this.getCurrentCategoryProductQuery)
      })
    }
  }
}
