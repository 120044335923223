<template>
  <div class="sort-by">
    <custom-dropdown :key-dropdown="'filter-by-sort'" :check-is-open="checkIsOpenBySort" dropdownBg="white"
      additionalClassesForDropdown="border-t border-grey-21">
      <template slot="button">
        <a href="javascript:void(0);" class="filter-by-type__link block text-center pl-md pr-24 bg-white text-dark tracking-lg uppercase text-h6 leading-lg h-13">
          <span class="uppercase">
            {{ $t(`Sort: ${sortbyLabel}`) }}
          </span>
          <i class="ss-gizmo ss-navigatedown absolute top-0 right-10px text-h5 text-black"></i>
        </a>
      </template>
      <template slot="content" v-if="openSort">
        <ul class="list-none p-0 -mx-px border-l border-r border-b">
          <li v-for="(option, label) in sortByAttribute" :key="option" class="cursor-pointer filter-by-type_item" v-if="sortby !== option">
            <a href="javascript:void(0);" @click="changeOrder(option, label)"
              class="hover:bg-dark hover:text-white filter-by-type__item text-dark tracking-lg uppercase text-h6 leading-lg px-md block slow-color-change">
                  {{ $t(label) }}
              </a>
          </li>
        </ul>
      </template>
    </custom-dropdown>
  </div>
</template>

<script>
import SortBy from 'theme/components/core/blocks/Category/SortBy'
import CustomDropdown from 'theme/components/core/blocks/Category/CustomDropdown'

export default {
  mixins: [SortBy],
  components: {
    CustomDropdown
  },
  data() {
    return {
      openSort: false
    }
  },
   methods: {
    checkIsOpenBySort(state) {
      this.openSort = state
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
